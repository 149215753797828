<template>
  <div class="pop_container">
    <div class="title_area">ENTER THE APPROVAL REFERENCE</div>
    <div class="body_area">
      <div class="form_section_generate_slip">
      <ValidationObserver ref="observer" v-slot="{ invalid }" slim>
        <table class="change_pass_table">
          <tr>
            <td>
         
            </td>
          </tr>
          <tr>
            <td>
          <ValidationProvider name="new pwd" rules="required" v-slot="{ errors }" slim>
              <div class="label_and_element_wrapper">
                <label>
                  Enter Manager Name
                 
                  <span>*</span>
                </label>
                <input type="text" v-model="manager" :class="errors[0] ? 'warning-border':''" />
              </div>
          </ValidationProvider>
            </td>
          </tr>
          <tr>
   
          </tr>
        </table>
      </ValidationObserver>
        <div class="pop_buttons_container">
          <a class="tr_cancel" @click="closeModal">Cancel</a>
          <a class="tr_save" @click="submitmanager">Save</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ApprovalReferencePopup",
  data(){
    return {
      manager:"",
     
    };
  },
  methods: {
    closeModal() {
      
      this.$emit("closeModal");
    },
  submitmanager(){
     
      this.$refs.observer.validate();
      if (this.manager != '') {
          this.$emit("submitmanager", this.manager);
          this.closeModal();
        } else {
          this.toast.error("Manager name must not be empty");
        }
    }
  },
  props: ["user"]
};
</script>
<style lang="scss">
.form_section_generate_slip table.change_pass_table {
  width: 400px;
  margin: auto;
  td {
    text-align: left;
    padding: 0;
    label {
      padding: 0;
      height: auto;
    }
  }
}
</style>